import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toggleCart } from '../../../redux/action';
import { Link, useNavigate } from "react-router-dom";
import { get_cart_Data } from '../../../redux/action';

const Header = () => {
    const cortData = useSelector((state) => state.cartReducer.cart);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getTotalQuantity = () => {
        return cortData?.data?.data?.reduce((acc, item) => acc + item.quantity, 0) || 0;
    };

    const totalQuantity = getTotalQuantity();

    const onCart = () => {
        dispatch(get_cart_Data());
        navigate("/cart");
    }

    useEffect(()=>{
        dispatch(get_cart_Data());
    },[])

    return (
        <>
            <div className="fw-row">
                <div className="fw-col-xs-12 ">
                    <div className="fw-divider-space" style={{ marginTop: "50px" }}></div>
                </div>
            </div>
            <div className="fw-row">
                <div className="navbar-frame">
                    <Link to="/"><img className="navbar-left-content" src="./assets/section-video/lifecode-logo.svg" /></Link>
                    <div className="navbar-right-content" style={{ display: "flex" }}>
                        <div className="d-flex align-items-center">
                            <img className="icon-call-sms" src="./assets/section-video/call-calling.svg" />
                            {/* <p class="navbar-right-text mb-0"> +91 9829197777</p> */}
                            <a href="tel:+919829197777" className="navbar-right-text mb-0">+91 9829197777</a>
                        </div>
                        <div className="separator-1 vector-class"></div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img className="icon-call-sms vector-class" src="./assets/section-video/sms.svg" />
                            <p className="navbar-right-text vector-class mb-0">sales@lifecode.co.in</p>
                        </div>
                        {/* <div className="separator-1 vector-class"></div> */}
                        {/* <div style={{ display: "flex", alignItems: "center" }}>
                            <Link to="/login" className="navbar-right-text vector-class mb-0">Login</Link>
                        </div>
                        <div className="separator-1 vector-class"></div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span onClick={() => onCart()} className="navbar-right-text vector-class mb-0">
                                {totalQuantity > 0 ? <span className=" cart_badge">{totalQuantity}</span> : null}
                                <div className="green-cart-svg"></div>
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;