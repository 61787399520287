import React, { useState, useEffect } from "react";
import './login.css';
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL, baseurl, SMS_API } from '../../_config';

const Login = () => {
    // State management
    const navigate = useNavigate();
    const [ph, setPh] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loginDetail, setLoginDetail] = useState([]);

    const getLoginDetail = async () => {
        try {
            const response = await axios.get(`${baseurl}${API_URL?.GetLoginDetailAll}`);
            if (response.status === 200) {
                setLoginDetail(response.data.data);
                // console.log("Fetched login detail", response.data.data);
            } else {
                setError("Failed to fetch product Data");
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
            setError("Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLoginDetail();
    }, [])

    // Send OTP function
    const sendOtp = async () => {
        const phoneNumber = ph.toString();

        const mobileExists = loginDetail.some(detail => detail.mobileNo.toString() === phoneNumber);

        if (!mobileExists) {
            window.alert("Mobile number is not registered");
            navigate('/shippingaddress'); 
            return;
        }
        setLoading(true);

        const apiUrl = `${SMS_API}?pMobileNo=${encodeURIComponent(ph)}`;
        try {
            const response = await axios.get(apiUrl, {
                timeout: 5000,
            });

            console.log(response.data, "OTP Sent Response");
            setLoading(false);
            setShowOtpInput(true);
            setGeneratedOtp(response.data.data.otp);
            toast.success("OTP Sent Successfully");
        } catch (error) {
            console.error('Error:', error.message);
            setLoading(false);
            toast.error("Failed to send OTP");
        }
    };

    // Verify OTP function
    const verifyOtp = () => {
        if (otp === generatedOtp) {
            toast.success("OTP Verified Successfully");
            localStorage.setItem('user', 'true');
            navigate('/');
        } else {
            toast.error("Invalid OTP");
        }
    };

    return (
        <div>
            <section className="container">
                <Header />
                <div className="login_wepper my-5">
                    <h2>Login</h2>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mobile_input">
                            <input
                                type="text"
                                name="mobile"
                                id="mobile"
                                placeholder="Enter Mobile Number"
                                value={ph}
                                onChange={(e) => setPh(e.target.value)}
                                disabled={showOtpInput} // Disable input if OTP input is shown
                            />
                        </div>
                        {showOtpInput && (
                            <div className="otp_input">
                                <input
                                    type="text"
                                    name="otp"
                                    id="otp"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </div>
                        )}
                        <button
                            type="button"
                            className="otp_btn"
                            onClick={showOtpInput ? verifyOtp : sendOtp}
                            disabled={loading}
                        >
                            {showOtpInput ? 'Verify OTP' : 'Send OTP'}
                        </button>
                    </form>
                </div>
            </section>
            <Footer />
            <Toaster />
        </div>
    );
};

export default Login;
