import React, { useState, useEffect } from "react";
import './cart.css';
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { removeItem, get_cart_Data, addToCart, incrQuantity, decrQuantity,deleteItem } from '../../redux/action';
// import { deleteItem } from '../../redux/action/index';
import { toast } from "react-hot-toast";

const Cart = (itemId) => {
    const cortData = useSelector((state) => state.cartReducer.cart);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleIncrementQuantity = async (item) => {
        setLoading(true);
        try {
            await dispatch(incrQuantity(item));
            toast.success('Item quantity updated successfully.');
        } catch (error) {
            toast.error('Error occurred while adding to cart.');
        } finally {
            setLoading(false);
        }
    };

    const handleDecrementQuantity = async (item) => {
        setLoading(true);
        try {
            await dispatch(decrQuantity(item));
            toast.success('Item quantity updated successfully.');
        } catch (error) {
            toast.error('Error occurred while adding to cart.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteItem = async (item) => {
        setLoading(true);
        try {
            await dispatch(deleteItem(item));
            console.log('Item delete successfully.');
        } catch (error) {
            toast.error('Error occurred while adding to cart.');
        } finally {
            setLoading(false);
        }
    };

    const cartTotal = cortData?.data?.data?.map(item => {
        return item.unitPrice * item.quantity;
    }).reduce((preVal, curVal) => {
        return preVal + curVal;
    }, 0);

    const formattedTotal = typeof cartTotal === 'number' ? cartTotal.toFixed(2) : '0.00';

    useEffect(() => {
        dispatch(get_cart_Data());
    }, [])


    return (
        <div>
            <section className="container">
                <Header />
                <div className="my-5">
                    <div className="cart_wepper_main">
                        <div className="row">
                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12">
                                {
                                    cortData?.data?.data?.map(item => {
                                        const { id, productImageDtos, displayName, salePrice, quantity, unitPrice,mrp } = item;

                                        if (quantity > 0) {
                                            return (
                                                <div className="row only_padding">
                                                    <div className="col-lg-4 col-sm-5 col-6">
                                                        <div className="img_werp">
                                                            <img src={`https://www.indianfilms.in/eCommerceAPI/${productImageDtos[0]?.imgPath}`} alt="" style={{ maxHeight: "150px", maxWidth: "150px" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 p-0 col-sm-7 col-6">
                                                        <div className="product_content_cart">
                                                            <h3>{displayName}</h3>
                                                            <p>Lifecode for {displayName}</p>
                                                            <p>₹ {unitPrice}</p>
                                                            <div className="remover_wepper">
                                                                <div className="counter_cart">
                                                                    <span className="down"
                                                                        onClick={() => handleDecrementQuantity(item)}
                                                                        role="button"
                                                                        aria-label="Decrease quantity"
                                                                    ><i className="fa-solid fa-minus"></i></span>
                                                                    <b className="text-white">{quantity}</b>
                                                                    <span className="up"
                                                                        onClick={() => handleIncrementQuantity(item)} disabled={loading}
                                                                        role="button"
                                                                        aria-label="Increase quantity"
                                                                    ><i className="fa-solid fa-plus"></i></span>
                                                                </div>
                                                                <div className="remove_inner mx-3" onClick={() => handleDeleteItem(item)} disabled={loading}>
                                                                    <span
                                                                       
                                                                        role="button"
                                                                        aria-label="Remove item"
                                                                    ><i className="fa-solid fa-trash"></i> Remove</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return null;
                                    })
                                }

                                <div className="cart_foot">
                                    <h3 className="text-white">
                                        <small>Total:</small>
                                        <b>₹ {formattedTotal}</b>
                                    </h3>

                                    {/* <button
                                        type="button"
                                        className="checkout_btn"
                                        disabled={cartTotal === 0}
                                    >
                                        Checkout
                                    </button> */}
                                </div>



                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                <div className="total_hedding">
                                    <h2>Shopping cart</h2>
                                    <div className="total_hedding_wepper">
                                        <div className="total_inner">
                                            <span>Subtotal</span>
                                            <p>₹ {formattedTotal}</p>
                                        </div>
                                        <Link to="/shippingaddress">Proceed to Buy</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Cart;