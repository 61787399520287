import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import './paymentreturn.css';
import axios from "axios";
import { API_URL, baseurl } from '../../_config';

const order_id = localStorage.getItem("order_id");

const PaymentReturn = () => {
    const [paymentResponse, setPaymentResponse] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const getPaymentRes = async () => {
        try {
            const response = await axios.get(`${baseurl}${API_URL?.UpdatePaymentResponse}?OrderId=${order_id}`);
            if (response.status === 200) {
                if (response.data.data.orderStatusId === 3 && response.data.data.paymentStatus === "captured") {
                    setPaymentResponse(response?.data?.data);
                    console.log("setPaymentResponse", response.data.data);
                } else {
                    setError("Failed to payment");
                }
            } else {
                setError("Failed to payment");
            }
        } catch (error) {
            console.error('Error payment:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPaymentRes();
    }, [])


    return (
        <div className="payment_return">
            <section>
                <div className="container">
                    <Header />
                    <div className="payment_status d-flex justify-content-center my-5">
                        <div className="text-center">
                            <div className="payment_status_gif" >
                                <img src="./assets/gif.gif" alt="" />
                            </div>
                            <h2>Your Order has been Palced</h2>
                            <div className="order_detail my-3">
                                <h4>{paymentResponse?.orderReqId}</h4>
                                {/* <h4>₹500</h4> */}
                            </div>
                            <div className="go_home_btn my-4">
                                <Link to="/">GO BACK TO HOME</Link >
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default PaymentReturn;