import React, { useState } from 'react';
import { imageurl, API_URL, baseurl } from '../../../_config';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../../redux/action';
import './productcard.css';
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";

const loginId = localStorage.getItem('loginId');
const user = localStorage.getItem('user');

const ProductsCard = (props) => {
    const cortData = useSelector((state) => state.cartReducer.cart);
    const { productImageDtos, displayName, salePrice, productId, amazoneUrl, unitPrice, mrp, categoryName, displayDesc } = props;
    const navigate = useNavigate();
    const products = cortData?.data?.data ?? [];

    const idString = String(productId).trim();

    const product = products.find(item => String(item.productId).trim() === idString);

    const [formData, setFormData] = useState({
        productId: productId,
        loginId: loginId,
        buyStatus: 0,
        quantity: product?.quantity ? product?.quantity : 1,
        isActive: true,
        createdBy: 0
    });

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        try {
            dispatch(addToCart(formData));
            console.log('save success', formData)
        } catch (error) {
            toast.error("Error occurred while adding to cart.");
        }
    };

    const onProductDetail = (pId) => {
        if (loginId) {
            handleSubmit();
        }
        navigate(`/productdetail/${pId}`);
        localStorage.setItem('pId', pId);
    };

    return (
        <>
            <div className="green-product-single col-sm-4 col-6 mt-4">
                <img className="green-products-image" src={`${imageurl}${productImageDtos[0]?.imgPath}`} />
                <p className="green-products-title mb-1" style={{ textTransform: "uppercase" }}>{displayName}</p>
                {/* <span style={{ color: "#ffffff" }}>ORDER NOW AT</span> */}
                <div className="centered-frame-products mt-3">
                    <a className="col-sm-6 byAmazone"
                        href={amazoneUrl} style={{ textDecoration: "none" }}>
                        <div style={{ display: "flex" }}>
                            <div className="green-cart-svg"></div>
                            <p className="green-buy-now">BUY NOW</p>
                        </div>
                    </a>
                    {/* <span className="col-6 byLifecode" style={{ textDecoration: "none" }} onClick={() => onProductDetail(productId)}>
                        <div className="" style={{ display: "flex" }}>
                            <p className="green-buy-now">LIFECODE</p>
                        </div>
                    </span> */}
                </div>
            </div>
        </>
    );
};

export default ProductsCard;