import React, { useRef, useEffect, useState } from "react";
import Header from "../Shared/Header";
import './productdetail.css';
import Footer from "../Shared/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { imageurl, API_URL, baseurl } from '../../_config';
import { useSelector, useDispatch } from 'react-redux';
import { get_cart_Data, incrQuantity, decrQuantity } from '../../redux/action';
import { addToCart } from '../../redux/action';

const ProductDetail = () => {
    const loginId = localStorage.getItem("loginId");
    const pId = localStorage.getItem('pId');
    const cortData = useSelector((state) => state.cartReducer.cart);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const [productDataById, setProductDataById] = useState([]);

    const products = cortData?.data?.data ?? [];
    // console.log('Products:', products);

    const idString = String(id).trim();

    const product = products.find(item => String(item.productId).trim() === idString);
    // console.log('Found product:', product?.quantity);

    // const handleIncrementQuantity = async (item) => {
    //     setLoading(true);
    //     try {
    //         await dispatch(incrQuantity(item));
    //         toast.success('Item quantity updated successfully.');
    //     } catch (error) {
    //         toast.error('Error occurred while adding to cart.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // const handleDecrementQuantity = async (item) => {
    //     setLoading(true);
    //     try {
    //         await dispatch(decrQuantity(item));
    //         toast.success('Item quantity updated successfully.');
    //     } catch (error) {
    //         toast.error('Error occurred while adding to cart.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const fetchProductDataById = async () => {
        try {
            const response = await axios.get(`${baseurl}${API_URL?.GetProductDetailById}?pProductDetailId=${pId}`);
            if (response.status === 200) {
                setProductDataById(response.data.data[0]);
            } else {
                setError("Failed to fetch product Data");
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
            setError("Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProductDataById();
    }, []);

    const onCheckout = (id) => {
        if (loginId) {
            navigate("/shippingaddress");
        } else {
            navigate('/register');
        }
    }

    return (
        <div>
            <section className="bg_pic">
                <div className="container">
                    <Header />
                    {/* <div className="hedding_product py-5">
                        <h2>Products</h2>
                    </div> */}
                    <div className="row my-5">
                        <div className="col-lg-5 col-sm-6 col-12">
                            <div className="prduct_img_book">
                                <div className="item">
                                    {/* <img src="./assets/section-green/green-wheeler.png" alt="zoom" className="w-100" /> */}
                                    {/* <img class="w-100" src={`${imageurl}${productDataById?.productImageDtos?.imgPath}`} alt="zoom"/> */}
                                    {productDataById?.productImageDtos?.map((item, i) => (
                                        <img className="w-100" src={`${imageurl}${item?.imgPath}`} alt="zoom" key={i} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-6 col-12">
                            <div className="product_content">
                                <h2 style={{ textTransform: "uppercase" }}>{productDataById?.displayName}</h2>
                                <p>{productDataById?.displayDesc}</p>
                                <span>₹{productDataById?.unitPrice} <del style={{ color: "#ffffffb5", fontSize: "18px" }}>{productDataById?.mrp}</del></span>
                                {/* <p>Ring Size : 9</p>
                                <p>Metal TypeYellow Gold</p> */}
                                <div className="btn_cunt">
                                    {/* <div className="counter">
                                        <span className="down"
                                            role="button"
                                            aria-label="Decrease quantity"
                                        ><i className="fa-solid fa-minus"></i></span>
                                        <span className="mb-0 quentity">{product?.quantity}</span>
                                        <span className="up"
                                            role="button"
                                            aria-label="Increase quantity"
                                        ><i className="fa-solid fa-plus"></i></span>
                                    </div> */}
                                    <div className="add_cart_btn">
                                        <Link to="/cart">Add To Cart</Link>
                                    </div>
                                    <div className="add_cart_btn" style={{ marginLeft: '20px' }}>
                                        <button onClick={()=>onCheckout(productDataById.productId)}>Buy Now</button>
                                </div>
                            </div>
                            <div className="categories mt-3">
                                <p><strong>Categories:</strong> {productDataById?.categoryName ? productDataById?.categoryName : "Lifecode"}</p>
                                {/* <p><strong>Tags:</strong> Diamond,Silver,Stone</p> */}
                            </div>
                        </div>
                    </div>
                </div>
        </div>

            </section >
    <Footer />
        </div >
    )
}

export default ProductDetail;