import React, { useState, useEffect } from "react";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { API_URL, baseurl } from '../../_config';
import { useSelector, useDispatch } from 'react-redux';
import { decrQuantity, deleteItem, get_cart_Data, incrQuantity } from "../../redux/action";
import './shippingaddress.css';
import useRazorpay from "react-razorpay";

const ShippingAddress = () => {
    const cortData = useSelector((state) => state.cartReducer.cart);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stateData, setStateData] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [couponCode, setCouponCode] = useState('');
    const [discountApplied, setDiscountApplied] = useState(false);
    const [userAddress, setUserAdderss] = useState([]);
    const [result, setResult] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [pLoginId, setpLoginId] = useState();
    const pId = localStorage.getItem('pId');
    const loginId = localStorage.getItem('loginId');

    const deliveryCharge = 20.00;
    const discountAmount = 100.00;

    useEffect(() => {
        const fetchCartData = async () => {
            try {
                await dispatch(get_cart_Data());
            } catch (error) {
                console.error('Failed to fetch cart data:', error);
                toast.error("Failed to fetch cart data");
            } finally {
                setLoading(false);
            }
        };

        fetchCartData();
    }, [dispatch]);

    const cartTotal = cortData?.data?.data?.map(item => {
        return item.unitPrice * item.quantity;
    }).reduce((preVal, curVal) => {
        return preVal + curVal;
    }, 0);

    // const formattedTotal = typeof cartTotal === 'number' ? cartTotal.toFixed(2) : '0.00';

    const formattedTotal = typeof cartTotal === 'number' ? cartTotal.toFixed(2) : '0.00';
    const subtotal = parseFloat(formattedTotal);
    const totalAmount = subtotal + deliveryCharge - (discountApplied ? discountAmount : 0);
    const formattedTotalAmount = totalAmount;

    const applyCoupon = () => {
        if (couponCode === 'FIP') {
            setDiscountApplied(true);
            setCouponCode('');
        } else {
            setDiscountApplied(false);
        }
    };

    const [formData, setFormData] = useState({
        loginId: 0,
        cartDetailIDtos: [
            {
                cartItemId: 0,
                productId: pId,
                loginId: 0,
                buyStatus: 0,
                quantity: 1,
                isActive: true,
                createdBy: 0
            }
        ],
        userAddressIDtos: [
            {
                addressId: 0,
                loginId: 0,
                addressTypeId: 1,
                name: "",
                emailId: "",
                contatctNo: "",
                addressLine1: "",
                addressLine2: "",
                districtId: 0,
                stateId: 0,
                countryId: 1,
                city: "",
                landmark: "",
                pincode: "",
                isActive: true,
                // createdBy: 0
            }
        ],
        addressId: 0,
        coopanId: 0,
        totalAmount: cartTotal,
        discountAmount: discountAmount,
        deliveryAmount: deliveryCharge,
        finalAmount: formattedTotalAmount
    });

    const getStateData = async () => {
        try {
            const response = await axios.get(`${baseurl}${API_URL?.GetState}`);
            if (response.status === 200) {
                setStateData(response?.data?.data);
                // console.log("Fetched state data:", response.data);
            } else {
                setError("Failed to fetch product Data");
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
            setError("Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const getDistrictData = async (stateId) => {
        try {
            const response = await axios.get(`${baseurl}${API_URL?.GetDistrictByStateId}?StateId=${stateId}`);
            if (response.status === 200) {
                setDistrictData(response?.data?.data);
                // console.log("Fetched district data:", response.data);
            } else {
                setError("Failed to fetch product Data");
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
            setError("Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (formData.userAddressIDtos[0].stateId) {
            getDistrictData(formData.userAddressIDtos[0].stateId);
        }
    }, [formData.userAddressIDtos[0].stateId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            userAddressIDtos: [{
                ...prevState.userAddressIDtos[0],
                [name]: value
            }]
        }));
    };

    const onUserDetailSave = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseurl}${API_URL?.ProductCheckout}`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(formData, "formData")
            if (response.status === 200) {
                console.log(response.data.data[0], "response")
                const { loginId } = response.data.data[0];
                setpLoginId(loginId);
                localStorage.setItem('loginId', loginId);
                localStorage.setItem('user', 'true');
                toast.success("Save Successfully");
                setIsSubmitted(true);
                getUserAddressByLoginId();
                get_cart_Data();
            } else {
                toast.error("Failed to submit form data");
            }
        } catch (error) {
            toast.error("Please try again later.");
        } finally {
            setFormData({
                cartDetailIDtos: [{
                    cartItemId: 0,
                    productId: pId,
                    loginId: 0,
                    buyStatus: 0,
                    quantity: 1,
                    isActive: true,
                    createdBy: 0
                }],
                userAddressIDtos: [{
                    addressId: 0,
                    loginId: 0,
                    addressTypeId: 0,
                    name: '',
                    addressLine1: '',
                    addressLine2: '',
                    landmark: '',
                    city: '',
                    districtId: 0,
                    stateId: 0,
                    countryId: 1,
                    pincode: '',
                    emailId: '',
                    contatctNo: '',
                    isActive: true,
                    createdBy: 0
                }],
                addressId: 0,
                coopanId: 0,
                totalAmount: 0,
                discountAmount: 0,
                deliveryAmount: 0,
                finalAmount: 0
            });
        }
    };

    const getUserAddressByLoginId = async () => {
        console.log(loginId)
        try {
            const response = await axios.get(`${baseurl}${API_URL?.GetUserAddressByLoginId}?pLoginId=${loginId}`);
            if (response.status === 200) {
                setUserAdderss(response.data.data[0]);
                console.log("Fetched address Data by id:", response.data.data);
            } else {
                setError("Failed to fetch address Data");
            }
        } catch (error) {
            console.error('Error fetching address data:', error);
            setError("Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStateData();
        getUserAddressByLoginId();
    }, [loginId]);

    const handleDeleteItem = async (item) => {
        setLoading(true);
        try {
            await dispatch(deleteItem(item));
            console.log('Item delete successfully.');
        } catch (error) {
            toast.error('Error occurred while adding to cart.');
        } finally {
            setLoading(false);
        }
    };

    const handleIncrementQuantity = async (item) => {
        setLoading(true);
        try {
            await dispatch(incrQuantity(item));
            // toast.success('Item quantity updated successfully.');
        } catch (error) {
            toast.error('Error occurred while adding to cart.');
        } finally {
            setLoading(false);
        }
    };

    const handleDecrementQuantity = async (item) => {
        setLoading(true);
        try {
            await dispatch(decrQuantity(item));
            // toast.success('Item quantity updated successfully.');
        } catch (error) {
            toast.error('Error occurred while adding to cart.');
        } finally {
            setLoading(false);
        }
    };

    const [productFormData, setProductFormData] = useState({
        orderId: 0,
        loginId: 35,
        discount: 100,
        discountCode: "FIP",
        referralCode: "LIFECODE",
        orderAmount: 100
    });

    const [Razorpay] = useRazorpay();

    const onCreateOrder = async () => {
        try {
            const response = await axios.post(`${baseurl}${API_URL?.SaveProductOrder}`, {
                orderId: productFormData.orderId,
                loginId: productFormData.loginId,
                discount: productFormData.discount,
                discountCode: productFormData.discountCode,
                referralCode: productFormData.referralCode,
                orderAmount: productFormData.orderAmount
            });

            console.log(response, "response")

            if (response.status === 200) {
                const result = response.data;
                loadRazorpayScript();
                const options = {
                    key: result.key,
                    amount: result.data.amount,
                    currency: result.data.currency,
                    name: result.data.name,
                    description: result.data.description,
                    image: result.data.image,
                    order_id: result.data.order_id,
                    prefill: result.data.prefill,
                    theme: result.data.theme,
                    callback_url: result.data.callback_url,
                    notes: result.data.notes
                };
                localStorage.setItem("order_id", result.data.order_id);
                // const rzp1 = new Razorpay(options);
                const paymentObject = new Razorpay(options);
                paymentObject.open();
            } else {
                console.log('error')
            }
        } catch (error) {
            setError("Failed to create order. Please try again later.");
            console.error('Error creating order:', error);
        }
    };

    const loadRazorpayScript = async () => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
    }

    return (
        <div className="frame-ASD">
            <Toaster />
            <div className="container pb-5">
                <Header />
                <div className="row justify-content-center">
                    <section className="billing_address_page col-md-7">
                        <div className="bg_pic">
                            <div className="from_wepper">
                                <span>Select a billing address from your address book or enter a new address.</span>
                                {/* {isSubmitted || loginId ? */}
                                <div className="loginid_data">
                                    <div className="loginid_address">
                                        <span className="userName">{userAddress?.name}</span>
                                        <span>{userAddress?.addressLine1} {userAddress?.addressLine2} {userAddress?.city} {userAddress?.districtName} {userAddress?.stateName} {userAddress?.countryName}</span>
                                    </div>
                                </div>
                                {/* : */}
                                {/* <form onSubmit={onUserDetailSave}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="fullname"
                                                        className="form-control"
                                                        placeholder="Full Name"
                                                        required
                                                        value={formData.userAddressIDtos[0].name}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="emailId"
                                                        id="email"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        required
                                                        value={formData.userAddressIDtos[0].emailId}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="contatctNo"
                                                        id="mobilenumber"
                                                        className="form-control"
                                                        placeholder="Mobile Number"
                                                        required
                                                        value={formData.userAddressIDtos[0].contatctNo}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="addressLine1"
                                                        id="addressline1"
                                                        className="form-control"
                                                        placeholder="Address Line1"
                                                        required
                                                        value={formData.userAddressIDtos[0].addressLine1}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="addressLine2"
                                                        id="addressline2"
                                                        className="form-control"
                                                        placeholder="Address Line2"
                                                        required
                                                        value={formData.userAddressIDtos[0].addressLine2}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <select
                                                        name="countryId"
                                                        id="country"
                                                        className="form-control"
                                                        value={formData.userAddressIDtos[0].countryId}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="0">Select country</option>
                                                        <option value="1">India</option>


                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <select
                                                        name="stateId"
                                                        id="state"
                                                        className="form-control"
                                                        value={formData.userAddressIDtos[0].stateId}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="0" className="option">Select state</option>
                                                        {stateData?.map((state, i) => (
                                                            <option key={state.stateId} value={state.stateId} className="option">{state?.stateName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <select
                                                        name="districtId"
                                                        id="district"
                                                        className="form-control"
                                                        value={formData.userAddressIDtos[0].districtId}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option className="option">Select District</option>
                                                        {districtData?.map((district, i) => (
                                                            <option key={district.districtId} value={district.districtId} className="option">{district?.districtName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        id="city"
                                                        className="form-control"
                                                        placeholder="City"
                                                        required
                                                        value={formData.userAddressIDtos[0].city}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="landmark"
                                                        id="landmark"
                                                        className="form-control"
                                                        placeholder="Landmark"
                                                        required
                                                        value={formData.userAddressIDtos[0].landmark}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="pincode"
                                                        id="zip"
                                                        className="form-control"
                                                        placeholder="Zip / postal code"
                                                        required
                                                        value={formData.userAddressIDtos[0].pincode}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-4 place_order_btn">
                                                <button type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form> */}
                                {/* } */}
                            </div>
                        </div>
                        <div className="mt-4 row justify-content-center mx-1">
                            {
                                cortData?.data?.data?.map(item => {
                                    const { id, productImageDtos, displayName, salePrice, quantity, unitPrice, mrp } = item;

                                    if (quantity > 0) {
                                        return (
                                            <div className="row only_padding" style={{ maxWidth: '100%' }}>
                                                <div className="col-lg-3 col-sm-5 col-6">
                                                    <div className="img_werp">
                                                        <img src={`https://www.indianfilms.in/eCommerceAPI/${productImageDtos[0]?.imgPath}`} alt="" style={{ maxHeight: "125px", maxWidth: "125px" }} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 p-0 col-sm-7 col-6">
                                                    <div className="product_content_cart">
                                                        <h3>{displayName}</h3>
                                                        <p>Lifecode for {displayName}</p>
                                                        <p>₹ {unitPrice}</p>
                                                        <div className="remover_wepper">
                                                            <div className="counter_cart">
                                                                <span className="down"
                                                                    onClick={() => handleDecrementQuantity(item)}
                                                                    role="button"
                                                                    aria-label="Decrease quantity"
                                                                ><i className="fa-solid fa-minus"></i></span>
                                                                <b className="text-white">{quantity}</b>
                                                                <span className="up"
                                                                    onClick={() => handleIncrementQuantity(item)} disabled={loading}
                                                                    role="button"
                                                                    aria-label="Increase quantity"
                                                                ><i className="fa-solid fa-plus"></i></span>
                                                            </div>
                                                            <div className="remove_inner mx-3"
                                                                onClick={() => handleDeleteItem(item)} disabled={loading}
                                                            >
                                                                <span
                                                                    role="button"
                                                                    aria-label="Remove item"
                                                                ><i className="fa-solid fa-trash"></i> Remove</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </div>
                    </section>
                    <div className="checkout d-flex justify-content-end mt-4 pt-1 col-md-4">
                        <div className="checkout_card text-white p-3" style={{ maxHeight: "330px" }}>
                            <h6 className="">Order Summary</h6>
                            <div className="payment_section">
                                <div className="coupon_input mb-4 mt-3 d-flex">
                                    <input
                                        type="text"
                                        className="col-9"
                                        value={couponCode}
                                        onChange={(e) => setCouponCode(e.target.value)}
                                        placeholder="Enter Coupon Code"
                                    />
                                    <button className="col-3" onClick={applyCoupon}>Apply</button>
                                </div>
                                <div>
                                    <div className="payment_detail pb-4">
                                        <div className="d-flex justify-content-between">
                                            <span className="left_heading">Subtotal</span>
                                            <span className="right_text">₹ {formattedTotal}</span>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <span className="left_heading">Delivery & Handling</span>
                                            <span className="right_text">₹ {deliveryCharge.toFixed(2)}</span>
                                        </div>
                                        {discountApplied && (
                                            <div className="d-flex justify-content-between mt-3">
                                                <span className="left_heading">Discount</span>
                                                <span className="right_text">₹ {discountAmount.toFixed(2)}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <span className="total_amount">Total</span>
                                        <span className="total_amount">₹ {formattedTotalAmount.toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className="my-4 place_order_btn">
                                    <button type="submit" onClick={onCreateOrder}>Pay Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ShippingAddress;
