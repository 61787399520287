import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Shared/Footer";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { imageurl, API_URL, baseurl } from '../../_config';
import Header from "../Shared/Header";
import ProductsCard from "./ProductsCard";

const Home = () => {
    const [isOpen, setIsOpen] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState([]);
    const navigate = useNavigate();

    const handleClick = (index) => {
        setIsOpen(isOpen === index ? null : index);
    };

    const faqs = [
        {
            question: 'How to get QR code?', answer: `You don’t have to wait so long for your QR code to be deliver at your
                                                    address, It is now available in the shop, so you can directly buy it and
                                                    you can fill the details and you can generate your own QR code and paste
                                                    it on the vehicle.` },
        {
            question: 'How to Register?', answer: `After downloading the app you have to register by clicking on register
                                                    your QR option, and then you have to select the type and enter your
                                                    vehicle number.` },
        {
            question: 'How to setup Emergency number?', answer: `QR code will have 2 emergency numbers of the rider’s relatives. In case
                                                    of emergency, anyone can use this app to scan the bar code and inform
                                                    the injurer’s relatives.`}
    ];

    const targetRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    useEffect(() => {
        // Define the Intersection Observer callback
        function handleIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                    observer.unobserve(entry.target);
                }
            });
        }

        // Create an IntersectionObserver instance
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });

        // Observe each target element
        targetRefs.forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        // Cleanup function to unobserve elements on component unmount
        return () => {
            targetRefs.forEach(ref => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    const fetchProductData = async () => {
        try {
            const response = await axios.get(`${baseurl}${API_URL?.GetProductDetailAll}`);
            if (response.status === 200) {
                setProductData(response.data.data);
                // console.log("Fetched product Data:", response.data.data);
            } else {
                setError("Failed to fetch product Data");
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
            setError("Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProductData();
    }, []);



    return (
        <div className="frame-ASD">
            <section className="section">
                <div className="top-video-container">
                    <video
                        src={`${process.env.PUBLIC_URL}/assets/section-video/lifecodemobile-video.mp4`}
                        playsInline
                        autoPlay
                        muted
                        loop
                        className="top-video-section"
                    />
                    <div className="video-overlay-content">
                        <div className="container">


                            <Header />
                            <div className="fw-row">
                                <div className="fw-col-xs-12 ">
                                    <div className="fw-divider-space" style={{ marginTop: "15vh" }}></div>
                                </div>
                            </div>
                            <h1 className="overlay-text-simplifies"><b>SIMPLIFIES <br /> EMERGENCY</b></h1>
                            <h6 className="overlay-text-available">Available On</h6>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <a href="https://play.google.com/store/apps/details?id=com.ishivax.lifecode&pcampaignid=web_share">
                                    <img className="img-appstore" src="./assets/section-video/googleplay.svg"
                                        style={{ marginRight: "10px" }} />
                                </a>
                                <a href="https://apps.apple.com/in/app/lifecode-simplifies-emergency/id1546741259">
                                    <img className="img-appstore" src="./assets/section-video/appstore.svg" />
                                </a>
                            </div>
                            <div className="reviews-container">
                                <h2 className="text-ratings">4.8</h2>
                                <div className="reviews-info">
                                    <img className="img-reviews" src="./assets/section-video/review-star.svg" />
                                    <h5 className="text-reviews">990+reviews</h5>
                                </div>
                                <div className="separator-2"></div>
                                <div className="reviews-info">
                                    <img className="img-reviews" src="./assets/section-video/happclient.svg" />
                                    <h5 className="text-reviews">Happy Clients</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section" style={{ background: "#000" }}>
                <div className="fw-container red-image-section">
                    <div className="container">
                        <div className="fw-row">
                            <div className="fw-col-xs-12 ">
                                <div className="divider-space-red-1"></div>
                            </div>
                        </div>
                        <div className="fw-row">
                            <div className="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 ">
                                <div className="sliding-text-content " ref={targetRefs[0]}>

                                    <h2 className="red-text-hope-you">HOPE YOU <span> NEVER <br />MEET AN </span>ACCIDENT</h2>
                                    <h3 className="red-text-but-if">BUT IF IT DOES, LIFECODE WILL HELP AND ASSIST.</h3>

                                </div>
                            </div>
                            <div className="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 slider-col-red">
                                <div className="sliding-mobile-content" ref={targetRefs[1]}>
                                    <div style={{ display: "flex" }}>
                                        <img className="red-mobile-img" src="./assets/section-red/red-mobile.png"
                                            style={{ display: "block", margin: "auto" }} />
                                        <img className="red-dot-img" src="./assets/section-red/red-dot.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fw-row">
                            <div className="content" style={{ display: "flex" }}>
                                <div className="fw-col-xs-12 fw-col-sm-5 fw-col-md-5 ">
                                </div>
                                <div className="fw-col-xs-12 fw-col-sm-3 fw-col-md-3 ">
                                    <div className="sliding-triangle-content" ref={targetRefs[2]}>
                                        <img className="red-triangle-img" src="./assets/section-red/red-triangle.png" />
                                    </div>
                                </div>
                                <div className="fw-col-xs-12 fw-col-sm-4 fw-col-md-4 ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="fw-container yellow-image-section">
                    <div className="container">
                        <div className="fw-row">
                            <div className="fw-col-xs-12 ">
                                <div className="divider-space-yellow-1"></div>
                            </div>
                        </div>
                        <div className="fw-row">
                            <div className="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 ">
                                <div className="sliding-text-content-yellow " ref={targetRefs[3]}>

                                    <p className="yellow-text-how-our"><b>HOW OUR <br /><span>APPLICATION </span><br />WORKS?</b>
                                    </p>
                                    <div className="faq-container">
                                        {faqs?.map((item, i) => (
                                            <div className="faq-item">
                                                <div className="faq-question" onClick={() => handleClick(i)}>
                                                    <span>{item?.question}</span>
                                                    <button className="toggle-btn">{isOpen === i ? '-' : '+'}</button>
                                                </div>
                                                <div className={`faq-answer ${isOpen === i ? 'open' : ''}`}>
                                                    <p>{item?.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="fw-col-xs-12 fw-col-sm-6 fw-col-md-6 slider-col-red">
                                <div className="sliding-qr-content" ref={targetRefs[4]}>
                                    <div style={{ display: "flex" }}>
                                        <img className="yellow-qr-img" src="./assets/section-yellow/yellow-scanqr.png" />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <img className="yellow-layer-img sliding-layer-content-yellow" ref={targetRefs[5]}
                        src="./assets/section-yellow/Layer-yellow.png" alt="Description of the image" />

                </div>
            </section>

            <section className="section">
                <div className="fw-container green-image-section">
                    <div className="container" style={{ zIndex: "1" }}>
                        <div className="fw-row">
                            <div className="fw-col-xs-12 ">
                                <div className="divider-space-yellow-1"></div>
                            </div>
                        </div>
                        <div className="fw-row">
                            <div className="fw-col-xs-12">
                                <div className="sliding-text-content-green" ref={targetRefs[6]}>
                                    <p className="green-text-prod">OUR PRODUCTS</p>
                                    <div className="fw-divider-space" style={{ marginTop: "20px" }}></div>
                                    <p className="green-text-ultimate">ULTIMATE <span> SAFETY </span>COMPANION</p>
                                    <div className="fw-divider-space" style={{ marginTop: "15px" }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="fw-row" style={{ position: "relative", zIndex: "1" }}>
                            <div className="sliding-products-content-green d-flex justify-content-center w-100" ref={targetRefs[7]}>
                                <div className="green-products-frame d-flex justify-content-center w-100">
                                    {error ? (
                                        <p>Error: {error}</p>
                                    ) : productData !== null ? (
                                        productData.length > 0 ? (
                                            <div className="row w-100 justify-content-center">
                                                {productData?.map((item, i) => (
                                                    // <div className="green-product-single">

                                                    //     <img className="green-products-image" src={`${imageurl}${item?.productImageDtos[0]?.imgPath}`} />
                                                    //     <p className="green-products-title" style={{ textTransform: "uppercase" }}>{item?.displayName}</p>
                                                    //     <span style={{ color: "#ffffff" }}>ORDER NOW AT</span>
                                                    //     <div className="centered-frame-products">
                                                    //         <a className="col-6"
                                                    //             href={item?.amazoneUrl} style={{ textDecoration: "none" }}>
                                                    //             <div style={{ display: "flex" }}>
                                                    //                 {/* <div className="green-cart-svg"></div>
                                                    //                 <p className="green-buy-now">Buy Now</p> */}
                                                    //                 <img src="./assets/button-amazone2.png" alt="" />
                                                    //             </div>
                                                    //         </a>
                                                    //         <span className="col-6" style={{ textDecoration: "none" }} onClick={() => onProductDetail(item?.productId)}>
                                                    //             <div className="" style={{ display: "flex" }}>
                                                    //                 {/* <div className="green-cart-svg"></div> */}
                                                    //                 {/* <p className="green-buy-now">Buy Now</p> */}
                                                    //                 <img src="./assets/button-lifecode2.png" alt="" />
                                                    //             </div>
                                                    //         </span>
                                                    //     </div>

                                                    // </div>

                                                    <ProductsCard key={item.productId} {...item} />
                                                ))}

                                            </div>
                                        ) : (
                                            <p>No banner available</p>
                                        )
                                    ) : (
                                        <p>Loading...</p>
                                    )}


                                </div>
                            </div>
                        </div>
                    </div>

                    <img className="green-stack-img sliding-stack-content-green" ref={targetRefs[8]}
                        src="./assets/section-green/green-stack-image.png" alt="Description of the image" />

                    <img className="green-half-circle-img sliding-circle-content-green" ref={targetRefs[9]}
                        src="./assets/section-green/green-half-circle.png" alt="Description of the image" />

                    <img className="green-unfill-circle-img" src="./assets/section-green/green-unfill-circle.png" ref={targetRefs[10]}
                        alt="Description of the image" />
                </div>
            </section>

            <section className="section w-100">
                <Footer />
            </section>

        </div>
    )
}

export default Home;