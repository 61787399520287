export const imageurl = "https://www.indianfilms.in/eCommerceAPI/";
export const baseurl = "https://www.indianfilms.in/eCommerceAPI/api/";
export const SMS_API = "https://ecommandar.com/smsapi/api/Supersinger/OTPSend";
export const API_URL = {
    SaveLoginDetail: 'LoginDetail/SaveLoginDetail',
    GetLoginDetailByUserPass: 'LoginDetail/GetLoginDetailByUserPass',
    GetLoginDetailAll: 'LoginDetail/GetLoginDetailAll',
    GetUserAddressByLoginId: 'UserAddress/GetUserAddressByLoginId',

    GetProductDetailAll: 'ProductDetail/GetProductDetailAll',
    GetProductDetailById: 'ProductDetail/GetProductDetailById',

    SaveCartDetail: 'CartDetail/SaveCartDetail',
    GetCartDetailAll: 'CartDetail/GetCartDetailAll',
    GetCartDetailByLoginId: 'CartDetail/GetCartDetailByLoginId',
    DeleteCartDetailById: 'CartDetail/DeleteCartDetailById',
    DeleteCartDetailByLoginId: 'CartDetail/DeleteCartDetailByLoginId',

    SaveUserAddress: 'UserAddress/SaveUserAddress',

    ProductCheckout: 'Checkout/ProductCheckout',
    GetState: 'State/GetState',
    GetDistrictByStateId: 'District/GetDistrictByStateId',

    CreateOrder: 'Checkout/CreateOrder',
    SaveProductOrder: 'ProductOrder/SaveProductOrder',
    UpdatePaymentResponse: 'ProductOrder/UpdatePaymentResponse'
}