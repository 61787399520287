import { ADD_TO_CART, DECREMENT, INCREMENT, REMOVE_ITEM, TOGGLE_CART, GET_ALL_CART_DATA } from "./actionTypes";
import { API_URL, baseurl } from '../../_config';
import axios from "axios";
import toast from "react-hot-toast";

export const get_cart_Data = () => async (dispatch) => {
    const loginId = localStorage.getItem('loginId');
    await axios.get(`${baseurl}${API_URL?.GetCartDetailByLoginId}?pLoginId=${loginId ? loginId : 0}`).then((data) => {
        if (data) {
            dispatch({ type: GET_ALL_CART_DATA, payload: data });
        } else {
            dispatch({ type: GET_ALL_CART_DATA, payload: [] });
        }
    })
};

export const addToCart = (formData) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseurl}${API_URL?.SaveCartDetail}`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            console.log(response.data, "response");
            toast.success("Item added to cart successfully");
            dispatch({ type: ADD_TO_CART, payload: response.data });
        } else {
            toast.error("Failed to add item to cart");
        }
    } catch (error) {
        // toast.error("Please try again later.");
    }
};

export const removeItem = (id) => {
    return {
        type: REMOVE_ITEM,
        payload: id
    };
};

export const deleteItem = (item) => async (dispatch) => {
    // const { cartReducer: { cart } } = getState();
    const loginId = localStorage.getItem('loginId');
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");

    if (!isConfirmed) {
        return;
    }

    try {
        const response = await axios.get(`${baseurl}${API_URL?.DeleteCartDetailByLoginId}`, {
            params: {
                pCartDetailId: item?.cartItemId,
                LoginId: loginId
            }
        });

        if (response.status === 200) {
            toast.success("Item deleted successfully");
            dispatch(get_cart_Data());
        } else {
            toast.error("Failed to delete item");
        }
    } catch (error) {
        console.error('Error deleting item:', error.response || error.message || error);
        // toast.error("Please try again later.");
    }
};

export const incrQuantity = (item) => async (dispatch, getState) => {
    const loginId = localStorage.getItem('loginId');
    const { cartReducer: { cart } } = getState();
    const formData = {
        productId: item.productId,
        loginId: loginId,
        buyStatus: 0,
        quantity: item.quantity + 1,
        isActive: true,
        createdBy: 0
    };

    try {
        await axios.post(`${baseurl}${API_URL?.SaveCartDetail}`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(get_cart_Data());
    } catch (error) {
        console.error('Error occurred while adding to cart:', error);
    }
};

export const decrQuantity = (item) => async (dispatch, getState) => {
    const loginId = localStorage.getItem('loginId');
    const { cartReducer: { cart } } = getState();
    const formData = {
        productId: item.productId,
        loginId: loginId,
        buyStatus: 0,
        quantity: item.quantity - 1,
        isActive: true,
        createdBy: 0
    };

    try {
        await axios.post(`${baseurl}${API_URL?.SaveCartDetail}`, formData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(get_cart_Data());
    } catch (error) {
        console.error('Error occurred while adding to cart:', error);
    }
};